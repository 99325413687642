import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import * as vars from './../vars.js';

import { ReactSession } from 'react-client-session';


var menuShown=false;


function Header(props) {

  const [showOrHideMM, setShowOrHideMM] = useState({});

  const [render, setRender] = useState(0);

  ReactSession.setStoreType("localStorage");

  ReactSession.set("last_location", window.location.href);
  

  window.addEventListener('resize', function(event) {
    if(menuShown) {
      setShowOrHideMM({display: "none"});
      menuShown=false;
    }
    //console.log('Resized? '+menuShown);
  }, true);

  function showMenu() {
    if(menuShown==false) {
      setShowOrHideMM({display: "inline-block"});
      menuShown=true;
    } else {

      setShowOrHideMM({display: "none"});
      menuShown=false;
    }
    
    //console.log('shown> '+menuShown);
  }

  function clicked(name) {

    if(name=='Logout') {
      props.logout();
    }

  }

  return (<>
  <div className="header no-print">
    <div className="header_cont">
      <div className="header_logo" onClick={()=>{window.open("/", '_self')}}>
      
      </div>
      <div className="header_right">
        <div className="text_header_items">
          {vars.pages.map((page, index) => {
            if(page.header && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
              return (<a key={index} onClick={()=>clicked(page.name)} href={page.href}><span className="header_menu_item"><img className="header_menu_img" src={"/img/menu_"+page.name.toLowerCase()+".png"} width="20" />{page.name}</span></a>);
            }
          })}
        </div>
        <div className="hamburger_icon" onClick={showMenu}>

        </div>
        <div className="mobile_menu" style={showOrHideMM}>
        {vars.pages.map((page, index) => {
            if(page.header && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
              return (
                <div key={index} className="mobile_menu_li">
                  <a href={page.href}><img className="header_mobile_menu_img" src={"/img/menu_"+page.name.toLowerCase()+".png"} width="30" />{page.name}</a>
                </div>
              );
            }
          })}


          
          
        </div>
      </div>
    </div>
  </div>
  <div className="header_sub  no-print">
    <a className="black_link" href="/availability">Service Now Available in the <u>Last Mountain area</u></a>
  </div>
  </>);

}
  
export default Header;