import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import ReceiptTable from './ReceiptTable';

import Button from 'react-bootstrap/Button';
import Loading from './Loading';
import PleaseLogin from './PleaseLogin';


import * as vars from './../vars.js';
import * as funcs from './../funcs.js';

var whenObj = {};
function AdminDashboard(props) {

  ReactSession.setStoreType("localStorage");

  const [loading, setLoading] = useState(true);

  const [customers, setCustomers] = useState([]);

  useEffect(() => {

    whenObj.account = props.account;
    whenObj.info = props.info;

    funcs.whenAccountAndInfoLoaded(whenObj, async function() {
  
      // Get our customerData
      const customerData = await funcs.get('/admin/customers', {
        account_token: ReactSession.get("account_token"),
        account_email: ReactSession.get("account_email")
      }, (errors)=>{});
      setCustomers(customerData);


      setLoading(false);

    });

   

  });

  if(loading) { return (<><Loading /></>); }
  if(!props.account) { return (<><PleaseLogin /></>); }


  return (
    <div className="page">
      <div className="text_block_black block_text_align_center">

          <div className="form_left">
            <h1>Admin Dashboard</h1>
            
            <table className="general_table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Services</th>
                
              </tr>
              {customers.map((customer, key) => (

                <tr key={key}>
                  <td><a href={"/adminCustomer/"+customer.email_address}>{customer.first_name} {customer.last_name}</a></td>
                  <td>{customer.email_address}</td>
                  <td>{customer.orders.length}</td>

                </tr>
                
              ))}
              </tbody>
            </table>

          </div>

      </div>

    </div>);

}
  
export default AdminDashboard;
