import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import * as vars from './../vars.js';

import Button from 'react-bootstrap/Button';

import { ReactSession } from 'react-client-session';

import uuid from 'react-uuid';
import { sha256, sha224 } from 'js-sha256';



function ReceiptTable(props) {

  ReactSession.setStoreType("localStorage");


  const [subtotal, setSubtotal] = useState(0);


  useEffect(() => {

    if(props.receipts) {

      var subTotal=0;
      for(var i=0;i<props.receipts.length;i++) {
        var oi = props.receipts[i];
        subTotal = subTotal + oi.price;
      }
      setSubtotal(subTotal);

    }
      
    
  });

  if(!props.receipts || props.receipts.length < 1) {
    return(<></>);
  }

  var odd=false;
  return (
    <>
    <div className="receipt_table_label"><h5>{props.label}</h5></div>
    <div className="rounded_table">
    <table className="receipt_table">
    <tbody>
      <tr>
        <th className="trFirst" width="80%">Item</th>
        <th className="trFirst right_td_item">Price</th>
      </tr>
      <tr><td><br/></td><td><br/></td></tr>
      {props.receipts.map((item, key) => {


        if(!odd) {
          odd=true;
        } else {
          odd=false;
        }
        return (
        <tr key={key} className={odd?'trOdd':'trEven'}>
          <td className="td_left">{item.label}<br/><div className="small_table_font">{item.shortDesc}</div></td>
          <td className="td_right right_td_item">${(Math.round(item.price * 100) / 100).toFixed(2)}</td>
          </tr>
        );

      }
      )}
      <tr><td><br/></td><td><br/></td></tr>
      <tr><td className="right_td_item">Subtotal</td><td className="right_td_item">${(Math.round(subtotal * 100) / 100).toFixed(2)}</td></tr>
      <tr><td className="right_td_item">GST</td><td className="right_td_item">${(Math.round(subtotal * 100*0.05) / 100).toFixed(2)}</td></tr>
      <tr><td className="right_td_item">PST</td><td className="right_td_item">${(Math.round(subtotal * 100*0.06) / 100).toFixed(2)}</td></tr>
      <tr><td className="right_td_item">Total</td><td className="right_td_item"><b>${(Math.round(subtotal * 100*1.11) / 100).toFixed(2)}</b></td></tr>
       <tr><td><br/></td></tr>
    </tbody>
    </table>
    </div>
    </>
        );

}
  
export default ReceiptTable;