import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';

function About(props) {

  const [shiftImage1Style, setShiftImage1Style] = useState({backgroundPosition: 'right -10px'});

  window.addEventListener("scroll", (event) => {
    if(window !== undefined) {
      let scroll = window.scrollY;
      //console.log(scroll);
      setShiftImage1Style({backgroundPosition: 'right '+(-scroll*0.075-10)+'px'});
    }
  });


  return (
    <div className="page">
      <Carousel>
        
{/*        <Carousel.Item>
           <div className="carousel_item" style={{backgroundImage: 'url("img/carousel_ads/deer_full.jpg")'}}>
            <div className="right_angled_area_text_carousel">
              <h1>Fall Internet Savings</h1>
              <h3>Have Everyone Paying Attention</h3>
              <span className="yellow_heading_caro">Save 50% on Installation</span>
            </div>
            <div className="square_ad_image">
              <img src="img/carousel_ads/deer_square.png" width="100%" />
            </div>
          </div>
        </Carousel.Item>*/}

        <Carousel.Item>
           <div className="carousel_item" style={{backgroundImage: 'url("img/carousel_ads/govan_full.jpg")'}}>
            <div className="right_angled_area_text_carousel less_top_margin">
              <span className="yellow_heading_caro">Rural<br/>Internet</span><br/><br/>
              <h3>Fast, affordable, and locally-owned.</h3><br/><br/><br/>
              <h5>Now available in Govan, SK.</h5>
            </div>
            <div className="square_ad_image">
              <img src="img/carousel_ads/govan_square.jpg" width="100%" />
            </div>
          </div>
        </Carousel.Item>

        {/*<Carousel.Item>
           <div className="carousel_item" style={{backgroundImage: 'url("img/carousel_ads/christmas_full.jpg")'}}>
            <div className="right_angled_area_text_carousel less_top_margin">
              <span className="yellow_heading_caro">Christmas<br/>Deals</span><br/><br/>
              <h5>First 3 months of<br/>Internet starting at</h5>
              <h3>$49/mo on the farm</h3>
              <h3>$44/mo in town</h3><br/>
              <Button onClick={() => { window.open('/availability', '_self')}} variant="warning" size="lg" active>Learn More</Button>
            </div>
            <div className="square_ad_image">
              <img src="img/carousel_ads/christmas_square.jpg" width="100%" />
            </div>
          </div>
        </Carousel.Item>*/}


        <Carousel.Item>
           <div className="carousel_item" style={{backgroundImage: 'url("img/carousel_ads/starlink_full.jpg")'}}>
            <div className="left_angled_area_text_carousel">
              <h1>Starlink Installations</h1>
              <span className="yellow_heading_caro_smaller">In Saskatchewan</span><br/><br/><br/>
              <Button onClick={() => { window.open('/starlink', '_self')}} variant="warning" size="lg" active>Learn more</Button>
            </div>
            <div className="square_ad_image">
              <img src="img/carousel_ads/starlink_square.jpg" width="100%" />
            </div>
            <div className="square_ad_button"><br/><br/>
              <h1>Starlink Installations</h1>
              <span className="yellow_heading_caro_smaller">In Saskatchewan</span><br/><br/><br/>
              <Button onClick={() => { window.open('/starlink', '_self')}} variant="warning" size="lg" active>Learn more</Button>
            </div>
            
          </div>
        </Carousel.Item>



        <Carousel.Item>
           <div className="carousel_item" style={{backgroundImage: 'url("img/carousel_ads/ptp_full.jpg")'}}>
            <div className="right_angled_area_text_carousel">
              <h1>Point-to-Point</h1>
              <span className="yellow_heading_caro_smaller">Internet in Outbuildings</span><br/><br/><br/>
              <Button onClick={() => { window.open('tel://1-825-435-1010', '_self')}} variant="warning" size="lg" active>Call or Text to Learn More</Button>
            </div>
            <div className="square_ad_image">
              <img src="img/carousel_ads/ptp_square.png" width="100%" />
            </div>
         
            
          </div>
        </Carousel.Item>

        <Carousel.Item>
           <div className="carousel_item" style={{backgroundImage: 'url("img/carousel_ads/aps_full.jpg")'}}>
            <div className="left_angled_area_text_carousel">
              <h1>Outdoor Access Points</h1>
              <span className="yellow_heading_caro_smaller">Extend WiFi on the Farm</span><br/><br/><br/>
              <Button onClick={() => { window.open('tel://1-306-900-4321', '_self')}} variant="warning" size="lg" active>1-306-900-4321</Button>
            </div>
            <div className="square_ad_image">
              <img src="img/carousel_ads/aps_square.png" width="100%" />
            </div>
         
            
          </div>
        </Carousel.Item>

        
      </Carousel>
     
      <div className="text_block_white">
        <h3>Last Mile Wireless Internet in Saskatchewan</h3>
        <p>Established in 2022, Last Mountain Wireless Inc. was created to provide cutting edge wireless Internet access and outstanding customer service to the Last Mountain area in Central Saskatchewan.</p>
      </div>
      <div className="text_block_black">

        <h3>AirFiber Internet</h3>
        <img src="img/ltu-xr_on_black.png" style={{"float": "right"}} />
        <h5><span className="brand_color_text">250+ Mbps Download Speeds</span></h5>
        <p>We use the latest fixed wireless technologies to bring <b>fiber optic internet speeds</b> from the tower to your home or business.</p>

        <p><i><b>Live rural</b>, but stay connected.</i></p>
        <Button onClick={() => { window.open('/availability', '_self')}} variant="warning" size="lg" active>View our Internet Plans</Button><br/>
      </div>
      <div className="text_block_blue" id="service_communities">
        <h3>Availability</h3>

        <p>The following is a list of communities and surrounding areas where we've currently established service:</p>

        <div className="serving">
          <h5><span className="brand_color_text">Strasbourg</span></h5>
          <img src="img/serving_strasbourg.png" width="200" /><br/><br/>
          <Button onClick={() => { window.open('/availability', '_self')}} variant="warning" size="sm" active>Available Now!</Button>
          <p></p>
        </div>

        <div className="serving">
          <h5><span className="brand_color_text">Duval</span></h5>
          <img src="img/serving_duval.png" width="200" /><br/><br/>
          <Button onClick={() => { window.open('/availability', '_self')}} variant="warning" size="sm" active>Available Now!</Button>
          <p></p>
        </div>
        <div className="serving">
          <h5><span className="brand_color_text">Govan</span></h5>
          <img src="img/serving_govan.jpg" width="200" /><br/><br/>
          <Button onClick={() => { window.open('/availability', '_self')}} variant="warning" size="sm" active>Available Now!</Button>
          <p></p>
        </div>
{/*        <br/><br/>
        <h5>But my community isn't on the list!</h5>
        <p><i>We're doing everything we can to build out to you as quickly as possible. We will update this list as we continue to grow, please check back often!</i></p>
*/}

      </div>

    </div>);

}
  
export default About;