import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import InternetPackage from './InternetPackage';

import GoogleMapReact from 'google-map-react';

import * as vars from './../vars.js';
import * as funcs from './../funcs.js';

import Geocode from "react-geocode";

import { ReactSession } from 'react-client-session';

import uuid from 'react-uuid';
import { sha256, sha224 } from 'js-sha256';

import Loading from './Loading';


const { encode } = require('pluscodes');

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDTfdfpJr-bqCFo2yhK3En9PvuyOt2mdFI");

// set response language. Defaults to english.
Geocode.setLanguage("en");


Geocode.setLocationType("ROOFTOP");




function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

var ourMap;
var ourMaps;
var loadMapOnce=false;
//var checkInterval;
var haveInfo=false;

//lat: 51.070031542445406, lng: -104.95430850982665
var defaultProps = {
  center: {
    lat: 51.15452191765686, lng: -105.0064935684204
  },
  zoom: 11
};

var doOnce;

var whenObj = {};

function Availability(props) {

  ReactSession.setStoreType("localStorage");

  const [validPreorder, setValidPreorder] = useState(false);

  const [loading, setLoading] = useState(true);
  
  const [serviceLocation, setServiceLocation] = useState(false);
  const [serviceAddress, setServiceAddress] = useState(false);

  const [followingPackagesString, setFollowingPackagesString] = useState("Please drag the marker to a service location above in order to see the available packages.");

  const [locationString, setLocationString] = useState("");
  const [coordsString, setCoordsString] = useState("Drag the marker to your desired service location");
 
  const [dragMessage, setDragMessage] = useState(true);

  const [availability, setAvailability] = useState(-1);

  function completeSetAvailability(inPreorderZone, inAvailableZone, inHsAvailableZone, inInTownZone, aCoordsString) {
    setAvailability(0);
    setFollowingPackagesString("No packages are available at: "+aCoordsString);
    if(inPreorderZone) {
      setAvailability(1); setFollowingPackagesString("The following packages are available at: "+aCoordsString);
    }
    if(inAvailableZone) {
      setAvailability(2); setFollowingPackagesString("The following packages are available at: "+aCoordsString);
    }
    if(inHsAvailableZone) {
      setAvailability(3); setFollowingPackagesString("The following packages are available at: "+aCoordsString);
    }
    if(inInTownZone) {
      setAvailability(4); setFollowingPackagesString("The following packages are available at: "+aCoordsString);
    }
  }


  let historicalOverlay;

  var loadMap = (map, maps) => {

    ourMap=map;
    ourMaps=maps;

    if(!maps) {
      return;
    }

    if(!props.info) {
      return;
    }

    loadMapOnce=true;



    // lat: 51.357998174056476, lng: -105.28733158111571
    const imageBounds = {
      north: 51.4495,
      south: 50.89192540574051,
      east: -104.70402622222899,
      west: -105.28767490386961,
    };

    // historicalOverlay = new google.maps.GroundOverlay(
    //   "/img/coverage_overlay2.jpg",
    //   imageBounds
    // );
   // historicalOverlay.setMap(map);

   


    function draggingEnded(thing) {
       setDragMessage(false);
      var pos = thing.getPosition().toJSON();
      console.log('{ lat: '+pos.lat+', lng: '+pos.lng+' }');

      // ReactSession.set("order_location_lat", pos.lat);
      // ReactSession.set("order_location_lng", pos.lng);

      setServiceLocation(pos);


      var inPreorderZone=false;
      for(var i=0;i<preorderPolys.length;i++) {
        var res = maps.geometry.poly.containsLocation(new maps.LatLng(pos.lat, pos.lng), preorderPolys[i]);
        if(res) {
          inPreorderZone = true;
        }
      }
      var inAvailableZone=false;
      for(var i=0;i<availablePolys.length;i++) {
        var res = maps.geometry.poly.containsLocation(new maps.LatLng(pos.lat, pos.lng), availablePolys[i]);
        if(res) {
          inAvailableZone = true;
        }
      }
      var inHsAvailableZone=false;
      for(var i=0;i<hsAvailablePolys.length;i++) {
        var res = maps.geometry.poly.containsLocation(new maps.LatLng(pos.lat, pos.lng), hsAvailablePolys[i]);
        if(res) {
          inHsAvailableZone = true;
        }
      }
      var inInTownZone=false;
      for(var i=0;i<inTownPolys.length;i++) {
        var res = maps.geometry.poly.containsLocation(new maps.LatLng(pos.lat, pos.lng), inTownPolys[i]);
        if(res) {
          inInTownZone = true;
        }
      }
      




      // Get address from latitude & longitude.
      Geocode.fromLatLng(pos.lat, pos.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);
          setLocationString(address);
          setLocationString('I would like service at');
          setCoordsString(address);
          //ReactSession.set("order_location_desc", address);
          setServiceAddress(address);
          completeSetAvailability(inPreorderZone, inAvailableZone, inHsAvailableZone, inInTownZone, address);

        },
        (error) => {
          //console.error(error);

          var encoded = encode({ latitude: pos.lat, longitude: pos.lng }); // '9FFW84J9+XG'
          console.log(encoded);
          setLocationString('I would like service at');
          setCoordsString((Math.round(pos.lat*10000)/10000)+', '+(Math.round(pos.lng*10000)/10000));
          completeSetAvailability(inPreorderZone, inAvailableZone, inHsAvailableZone, inInTownZone, (Math.round(pos.lat*10000)/10000)+', '+(Math.round(pos.lng*10000)/10000));

        }
      );

    }

    var preorderPolys = [];
    var availablePolys = [];
    var hsAvailablePolys = [];
    var inTownPolys = [];


    if(props.info) {
      for(var i=0;i<props.info.preorderCoords.length;i++) {

        preorderPolys[i] = new maps.Polygon({
          paths: props.info.preorderCoords[i],
          strokeColor: "#e8b82e",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#e8b82e",
          fillOpacity: 0.35
        });
        preorderPolys[i].setMap(map);

      }


      
      for(var i=0;i<props.info.availableCoords.length;i++) {

        availablePolys[i] = new maps.Polygon({
          paths: props.info.availableCoords[i],
          strokeColor: "#3063a2",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#254d7e",
          fillOpacity: 0.35
        });
        availablePolys[i].setMap(map);

      }

      
      for(var i=0;i<props.info.hsAvailableCoords.length;i++) {

        hsAvailablePolys[i] = new maps.Polygon({
          paths: props.info.hsAvailableCoords[i],
          strokeColor: "#e27d2c",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#e2862c",
          fillOpacity: 0.35
        });
        hsAvailablePolys[i].setMap(map);

      }

      for(var i=0;i<props.info.inTownCoords.length;i++) {

        inTownPolys[i] = new maps.Polygon({
          paths: props.info.inTownCoords[i],
          strokeColor: "#e22c46",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#e22c46",
          fillOpacity: 0.35
        });
        inTownPolys[i].setMap(map);

      }
    }


    
    
    let marker = new maps.Marker({
      position: {lat: ReactSession.get("order_location_lat")?ReactSession.get("order_location_lat"):props.info.preorderCenter.lat, lng: ReactSession.get("order_location_lng")?ReactSession.get("order_location_lng"):props.info.preorderCenter.lng},
      map,
      draggable: true,
    });

    // if(ReactSession.get("order_location_lat")) {
      
    // }
    // setTimeout(function() {
    //     draggingEnded(marker);
    //   }, 500);

    marker.addListener('dragend', function (e) {
      //console.log(this.getPosition().toJSON()); // this === marker
     // console.log(marker);

     draggingEnded(this);
      



    });

    

    



  };


  async function detectPreorder() {
    const detectPreorder = await funcs.get('/orders/detectPreorder', {
      account_email: ReactSession.get("account_email"),
      account_token: ReactSession.get("account_token")
    }, (errors)=>{
      
    });

    if(detectPreorder.length>0) {
      setValidPreorder(true);
    } else {
      setValidPreorder(false);
    }

  }


  
  

  const [shiftImage1Style, setShiftImage1Style] = useState({backgroundPosition: 'right -10px'});

  window.addEventListener("scroll", (event) => {
    if(window !== undefined) {
      let scroll = window.scrollY;
      //console.log(scroll);
      setShiftImage1Style({backgroundPosition: 'right '+(-scroll*0.075-10)+'px'});
    }
  });

  useEffect(() => {

    whenObj.info = props.info;

    funcs.whenInfoLoaded(whenObj, async function() {
  

      haveInfo=true;
      detectPreorder();

      setLoading(false);

    });

    
  });


 


 
  async function clickedOrder(id) {

    var servLocString = serviceLocation.lat+', '+serviceLocation.lng;

    if(servLocString=='undefined, undefined') {
      servLocString = props.info.preorderCenter.lat+', '+props.info.preorderCenter.lng;
    }

    window.open('tel://13069004321', '_self');

    // const createServiceOrder = await funcs.post('/orders/orderServiceCreate', {
    //   account_email: ReactSession.get("account_email"),
    //   account_token: ReactSession.get("account_token"),
    //   package_id: id,
    //   service_location: servLocString
    // }, (errors)=>{

    //   //setCsoErrors(errors);
    //   window.open('#first_error', '_self');


    //   if(errors[0].code=='INVALID_TOKEN') {
    //     // If this is an account redirect error, set the account creation and login locations and messages...
    //     ReactSession.set("next_reason", 'To order service');
    //     ReactSession.set("next_url", errors[0].next_url);
    //   }

    //   for(var i=0;i<errors.length;i++) {
    //     if(errors[i].redirect_url) {
    //       window.open(errors[0].redirect_url, '_self');
    //     }
    //   }

      
    // });

    // // Handle these errors locally or proceed...
    // if(!createServiceOrder.errors) {
    //   window.open(createServiceOrder.redirect_url, '_self');
    // }
    


  }





  if(loading) { return (<><Loading /></>); }

  return (
    <div className="page">
      <div className="text_block_white">
        <h1>Availability Map</h1>
        <p>This tool details our network coverage area and available internet plans. Call us at <span className="blue_link"><b><a  href="tel://13069004321">1.306.900.4321</a></b></span> for more information, or to order service.</p>
      </div>

      <div style={{ height: '500px', width: '100%' }}>

          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyDTfdfpJr-bqCFo2yhK3En9PvuyOt2mdFI" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
            

          >

            
          </GoogleMapReact>
          <div className="pleaseDrag">
            {locationString} <b>{coordsString}</b>
          </div>

          <div className={"serviceAvailable "+((availability==2 || availability==3)?"block":"hidden")}>
            <div className="sa_left"><img width="40" src="img/check400.png" /></div>
            <div className="sa_right">
            Service is available at this location!<br/>
            <a className="white_link" href="#view_packages"><b>View Packages</b></a></div>
          </div>
          <div className={"preorderAvailable "+(availability==1?"block":"hidden")}>
            <div className="sa_left"><img width="40" src="img/exclaim400.png" /></div>
            <div className="sa_right">
            Service not yet established here, but pre-order is available. <a className="white_link" href="#view_packages"><b>View Packages</b></a></div>
          </div>
          <div className={"notAvailable "+(availability==0?"block":"hidden")}>
            <div className="sa_left"><img width="40" src="img/x400.png" /></div>
            <div className="sa_right">
            Service not yet established here, pre-order not available. <a className="white_link" href="#learn_more"><b>Learn More</b></a></div>
          </div>

        </div>


      <div className="text_block_blue"  id="view_packages">

        <h1>Plans</h1>

        <p><i>{followingPackagesString}</i></p><br/>

        

        

        {/*<span className={validPreorder?'':'hidden'}><h2><i>You have a valid preorder!</i></h2></span>*/}

        

        {/*<InternetPackage info={props.info} packageId="0" availability={availability} clickedOrder={()=>{clickedOrder(0)}} />*/}

     

        {props.info.packages.map((aPkg, index)=>{
          //console.log(aPkg+' + '+index);
           return <InternetPackage key={index} info={props.info} packageId={index} availability={availability} clickedOrder={()=>{clickedOrder(index)}} />
       })}
        
       {/* <InternetPackage info={props.info} packageId="0" availability={availability} clickedOrder={()=>{clickedOrder(0)}} />

        {/*<h3>Call <span className="yellow_link"><a  href="tel://18254351010">1.825.435.1010</a></span> to Order Now</h3>*/}
      </div>

{/*      <div className="text_block_black" id="learn_more">
        <h3>Our Network is Still Growing</h3>
        <p>Our company is new and our network is growing. We have not yet established service widely across Saskatchewan but we're growing fast and this is our goal.</p>
        <p>If you continue to be interested in service, please check back in on this availability tool. This map is subject to frequent change and we will always keep it up-to-date.</p>
      </div>*/}


    </div>);

}
  
export default Availability;

