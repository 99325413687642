import React, {useState, useEffect} from 'react';

import { Link, useParams } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import ReceiptTable from './ReceiptTable';

import Button from 'react-bootstrap/Button';
import Loading from './Loading';
import PleaseLogin from './PleaseLogin';

import * as vars from './../vars.js';
import * as funcs from './../funcs.js';

import { addDays, subDays, getDay } from 'date-fns';

var whenObj = {};
function AdminCustomer(props) {

  let { ce } = useParams();

  ReactSession.setStoreType("localStorage");

  const [loading, setLoading] = useState(true);

  const [customer, setCustomer] = useState(false);

  useEffect(() => {

    whenObj.account = props.account;
    whenObj.info = props.info;

    funcs.whenAccountAndInfoLoaded(whenObj, async function() {
  
      //const queryParams = new URLSearchParams(window.location.search);
      const customerData = await funcs.get('/admin/customer', {
        account_token: ReactSession.get("account_token"),
        account_email: ReactSession.get("account_email"),
        customer_email: ce
      }, (errors)=>{});
      setCustomer(customerData);


      setLoading(false);

    });

   

  });



  if(loading || !customer) { return (<><Loading /></>); }
  if(!props.account) { return (<><PleaseLogin /></>); }
 



  function lineItems(order) {

    var orderItemsLine='';

    for(var i=0;i<order.receipts.length;i++) {
      var rcpt = order.receipts[i];

      for(var j=0;j<rcpt.length;j++) {
        var lineItem = rcpt[j];
        
        if(lineItem.data&&lineItem.data.service_location&&lineItem.data.service_location.lat && lineItem.data.service_location.lng) {
                          orderItemsLine = orderItemsLine+'@'+(Math.round(lineItem.data.service_location.lat*100)/100)+', '+(Math.round(lineItem.data.service_location.lng*100)/100)+' ';
                        }
      }

    }

    return orderItemsLine;

  }
  var odd=false;
  var orderItemsLine='';

  if(!props.info) {
    return (<></>);
  }



  return (
    <div className="admin_page">
      <div className="text_block_black">

          
            <h1>Admin: Customer</h1>
            <h3>{customer.first_name} {customer.last_name}</h3>
            <br/>
            
            <table className="general_table">
            <tbody>
              <tr>
                <th>Email</th>
                <th>Orders</th>
                <th>Subscriptions</th>
              </tr>
              <tr>
                <td>{customer.email_address}</td>
                <td>{customer.orders?customer.orders.length:''}</td>
                <td>{customer.subscriptions?customer.subscriptions.length:''}</td>
              </tr>
              </tbody>
            </table>

            <br/><br/>
            <h5>Orders</h5>
            <table className="general_table">
            <tbody>
              <tr>
                <th>Order #</th>
                <th>Dates</th>
                <th>Line Items</th>
              
                

                <th>Status</th>
                <th>Payment</th>
                <th>Type</th>
                <th>Payments</th>
                <th>Order</th>


              </tr>
              {customer.orders?customer.orders.map((order, key) => {

                if(!odd) {
                  odd=true;
                } else {
                  odd=false;
                }
                
                return (
     
                <tr key={'orders_'+key} className={odd?'trOdd':'trEven'} style={{backgroundColor: funcs.hexToRgbA(funcs.stringToColour(order.number), 0.25)}}>
                  <td>{order.number}</td>
                  <td>Cr {order.created_at}<br/>Up {order.updated_at}<br/>Or {order.ordered_at}</td>

                  <td>
                    {order.receipts.map((receipt, key2) => receipt.map((lineItem, key3) => {
                        // console.log('lineItem:');
                        // console.log(lineItem);
                        return ( <div key={key3}>{lineItem.label}<br/></div>);
                      }))}
                  </td>
                  
                  
                  
     
                  <td>{props.info.orderStatuses[order.status].name}</td>
                  <td>${}</td>
                  <td>{props.info.orderTypes[order.type].name}</td>
                  <td>
                  <Button className={"white_link "+((order.status=='refunded'||order.status=='cancelled')?'hidden':'')} variant="danger" size="sm" onClick={()=>{ 
                    var url = funcs.redirectUrlWithMessage({
                        label: 'Issue Refund',
                        description: ('This will refund the entire payment associated with this order. Remember that Internet packages are billed seperately as a subscription. Are you sure you want to do this?'),
                        redirect_url: vars.apiUrl+'/admin/refundPaymentIdAndAmount?account_token='+ReactSession.get("account_token")+'&account_email='+ReactSession.get("account_email")+'&payment_id='+order.square_payment.id+'&amount='+order.square_payment.amount_money.amount,
                        link_label: 'Yes',
                        link_variant: 'success',
                        redirect2_url: '/adminCustomer/'+ReactSession.get("account_email"),
                        link2_label: 'No',
                        link2_variant: 'danger'
                    });

                    window.open(url, '_self');


                  }}>
                    Refund
                  </Button>
                  </td>
                  <td>
                  <Button className={"white_link "+(order.status=='cancelled'?'hidden':'')} variant="danger" size="sm" onClick={()=>{ 
                    var url = funcs.redirectUrlWithMessage({
                        label: 'Set Order to Cancelled',
                        description: ('This will set this order to cancelled. (You must manually refund this order as well if you plan to give the customer their money back.) Are you sure you want to do this?'),
                        redirect_url: vars.apiUrl+'/admin/setServiceOrderToCancelled?account_token='+ReactSession.get("account_token")+'&account_email='+ReactSession.get("account_email")+'&order_number='+order.number,
                        link_label: 'Yes',
                        link_variant: 'success',
                        redirect2_url: '/adminCustomer/'+ReactSession.get("account_email"),
                        link2_label: 'No',
                        link2_variant: 'danger'
                    });

                    window.open(url, '_self');


                  }}>
                    Cancel
                  </Button>
                  
                  </td>
                  

                </tr>
      
               
                
              ); }
              )
            :''}
              </tbody>
            </table>


            <br/><br/>
            <h5>Subscriptions</h5>
            <table className="general_table">
            <tbody>
              <tr>
                <th>From Order #</th>
                <th>Package</th>
                <th>StartDate</th>
                <th>ActionDate</th>
                <th>ChargedThroughDate</th>
                <th>Status</th>
                <th>Paid Total</th>
                <th>InvoiceIds</th>
                <th>Service</th>
              </tr>
              {customer.subscriptions?customer.subscriptions.map((sub, key) => {

                var invPaidTotal=0;
                if(sub.invoices) {
                  for(var i=0;i<sub.invoices.length;i++) {
                    var invoice = sub.invoices[i];
                    for(var j=0;j<invoice.payment_requests.length;j++) {
                      var pr = invoice.payment_requests[j];
                      invPaidTotal = invPaidTotal+pr.total_completed_amount_money.amount/100;
                    }
                  }
                }
                
                return (

                <tr key={'subs_'+key} style={{backgroundColor: funcs.hexToRgbA(funcs.stringToColour(sub.initial_order_number), 0.25)}}>
                  <td>{sub.initial_order_number}</td>
                  <td>{sub.package.name}</td>
                  <td>{sub.start_date}</td>
                  <td>{sub.cancelling_date}{sub.resuming_date}{sub.pausing_date}</td>
                  <td>{sub.charged_through_date}</td>
                  <td>{sub.status.name}</td>
                  <td>${invPaidTotal}</td>
                  <td>{sub.invoices?sub.invoices.length:''}</td>
                  <td>

                  <Button className={"white_link "+(sub.canceled_date?'hidden':'')} variant="danger" size="sm" onClick={()=>{ 
                    var url = funcs.redirectUrlWithMessage({
                        label: 'Manually Cancel Subscription',
                        description: ('This will cancel the customer\'s subscription. Are you sure you want to do this?'),
                        redirect_url: vars.apiUrl+'/admin/cancelSubscription?account_token='+ReactSession.get("account_token")+'&account_email='+ReactSession.get("account_email")+'&sub_number='+sub.number,
                        link_label: 'Yes',
                        link_variant: 'success',
                        redirect2_url: '/adminCustomer/'+ReactSession.get("account_email"),
                        link2_label: 'No',
                        link2_variant: 'danger'
                    });

                    window.open(url, '_self');


                  }}>
                    Cancel
                  </Button>
                  </td>
                </tr>
                
              ); }
              )
            :''}
              </tbody>
            </table>

   

      </div>

    </div>);

}
  
export default AdminCustomer;
