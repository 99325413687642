import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

function PleaseLogin(props) {

  return (<>
    <div className="page">
      <div className="text_block_white block_text_align_center">
        <p>Oops something went wrong, you need to be logged in to see this page!</p>
        <p><a href="/login">Log in now.</a></p><br/>
      </div>
    </div>
  </>);

}
  
export default PleaseLogin;