import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";


var doOnce=false;
var loadingText1='Loading...';
function Loading(props) {

  const [loadingText, setLoadingText] = useState('Loading...');

  useEffect(() => {

    if(!doOnce) {
      doOnce=true;

      setInterval(function() {

        switch(loadingText1) {
          case 'Loading...':
          loadingText1 = 'Loading';
          break;
          case 'Loading':
          loadingText1 = 'Loading.';
          break;
          case 'Loading.':
          loadingText1 = 'Loading..';
          break;
          case 'Loading..':
          loadingText1 = 'Loading...';
          break;
        }

        setLoadingText(loadingText1);
      }, 500);


    }

    
    
    
  });
 

  return (<>
    <div className="page">
      <div className="text_block_white block_text_align_center">
      <img src="/img/loading.gif" /><br/>
        {loadingText}
      </div>
    </div>
  </>);


}
  
export default Loading;