import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { addDays, subDays, getDay } from 'date-fns';


var parseOnce=false;

function StarlinkBooked(props) {


  const [existingObj, setExistingObj] = useState(false);



  useEffect(() => {
    if(!parseOnce) {
      const queryParams = new URLSearchParams(window.location.search);
      const obj = queryParams.get('obj');
      setExistingObj(JSON.parse(obj));
      console.log(existingObj);
      if(existingObj) {
        parseOnce=true;
      }
      
    }
    
    
  });
  

  return (
    <div className="page">
      <div className="image2">
        <h1>Request Received</h1>
        <p>We have received your request for a quote{existingObj?' '+existingObj.name:''}, we'll reach out to you shortly!</p>
        <p>Feel free to call or text us directly at <span className="yellow_link"><a href="tel://1.825.435.1010">1.825.435.1010</a></span></p>
      </div>
      

    </div>);

}
  
export default StarlinkBooked;