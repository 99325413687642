import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';



import { ReactSession } from 'react-client-session';

import Loading from './Loading';
import PleaseLogin from './PleaseLogin';
import LmwForm from './LmwForm';

import * as vars from './../vars.js';
import * as funcs from './../funcs.js';

import { addDays, subDays, getDay } from 'date-fns';



var whenObj = {};
function Dashboard(props) {

  const [loading, setLoading] = useState(true);

  const [orders, setOrders] = useState([]);
  const [services, setServices] = useState([]);

  const [errors, setErrors] = useState([]);
  
  useEffect(() => {

    whenObj.account = props.account;
    whenObj.info = props.info;

    funcs.whenAccountAndInfoLoaded(whenObj, async function() {
  

      // Get our orders
      const ordersData = await funcs.post('/orders/', {
        account_token: ReactSession.get("account_token"),
        account_email: ReactSession.get("account_email")
      }, (errors)=>{});
      setOrders(ordersData);

      // Get our services
      const servicesData = await funcs.post('/services/', {
        account_token: ReactSession.get("account_token"),
        account_email: ReactSession.get("account_email")
      }, (errors)=>{});
      setServices(servicesData);


      setLoading(false);

    });

    
  });

  if(loading || !props.info) { return (<><Loading /></>); }
  if(!props.account) { return (<><PleaseLogin /></>); }


  return (
    <div className="page">

      <div className="text_block_white">
        <h1>Your Dashboard</h1>
        <p>Welcome <b>{(props.account?props.account.first_name:'')}</b>, the following is an overview of your account information, orders and services.</p>
        <h3>Account Information</h3>

        <table className="account_info_table">
        <tbody>
          <tr>
          <th>Basic Information</th>
          <th>Billing Address</th>
          </tr>
          <tr>
            <td>
              <div className="account_info_line">Name: <b>{props.account.first_name} {props.account.last_name}</b></div>
              <div className={"account_info_line "+(props.account.company?'':'hidden')}>Company: <b>{props.account.company}</b></div>
              <div className="account_info_line">Email: <b>{props.account.email_address}</b></div>
              <div className="account_info_line">Phone: <b>{props.account.phone_number}</b></div>
            </td>
            <td>
              <div className="account_info_line">Name: <b>{props.account.billing_address.cardholder_name}</b></div>
              <div className={"account_info_line "+(props.account.billing_address.address_line_1?'':'hidden')}>Address Line 1: <b>{props.account.billing_address.address_line_1}</b></div>
              <div className={"account_info_line "+(props.account.billing_address.address_line_2?'':'hidden')}>Address Line 2: <b>{props.account.billing_address.address_line_2}</b></div>
              <div className="account_info_line">City/Town: <b>{props.account.billing_address.locality}</b></div>
              <div className="account_info_line">Province: <b>{props.account.billing_address.province}</b></div>
              <div className="account_info_line">Postal Code: <b>{props.account.billing_address.postal_code}</b></div>
              <div className="account_info_line">Country: <b>{props.account.billing_address.country}</b></div>
            </td>
          </tr>
          </tbody>
        </table><br/>
        <Button href="/updateAccount" variant="warning" size="sm" active>Update this Information</Button>
      </div>

      <div className="text_block_blue">
        <h3>Your Services</h3>
        <table className="general_table">
        <tbody>
          <tr>
          <th></th>
            <th>Plan</th>
            <th>Status</th>
            <th>Price</th>
            <th>Paid Until</th>
          </tr>
          {services.map((sub, key) => {
            
            return (

            <tr key={key}>
            <td><div style={{width: '20px', height: '20px', backgroundColor: funcs.stringToColour(sub.initial_order.number)}}></div></td>
              <td><a href={"/services/"+sub.number}>{sub.package.name}</a></td>
              <td><div style={{display:'inline-block', borderRadius: '12px', width:'12px',height:'12px',backgroundColor:funcs.serviceStatusColourFromSubscription(sub)}}></div> {sub.status.name}</td>
              <td>${funcs.numberWithCommas(Math.round(sub.package.price*1.11*100)/100)}/mo</td>
              <td>{funcs.billedThroughDateFromSubscription(sub)}</td>
            </tr>
            
          ); }
          )
        }
          </tbody>
        </table>
      </div>
      <div className="text_block_blue">
        <h3>Your Orders</h3>
        <table className="general_table">
        <tbody>
          <tr>
            <th></th>
            
            <th>Order #</th>
            <th>Date</th>
            <th>Type</th>
            
  
            <th>Status</th>
            <th>Total</th>
          </tr>
          {orders.map((order, key) => (

            <tr key={key}>
              <td><div style={{width: '20px', height: '20px', backgroundColor: funcs.stringToColour(order.number)}}></div></td>
              <td><a href={'/orders/'+order.number}>{order.number}</a></td>
              <td>{order.ordered_at}</td>
              
              <td>{props.info.orderTypes[order.type]?props.info.orderTypes[order.type].name:''}{order.preorder_applied_to_order?' -> #'+order.preorder_applied_to_order.number:''}</td>
              
              
  
              <td>{props.info.orderStatuses[order.status].name}<a className={'white_link '+(order.status=='checking_out'?'':'hidden')} href=""> - Resume</a></td>
              <td>${funcs.numberWithCommas(funcs.getTotalForServiceOrder(order, props.info.packages))}</td>
            </tr>
            
          ))}
          </tbody>
        </table>
      </div>


      

    </div>);

}
  
export default Dashboard;
