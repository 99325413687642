import React, {useState, useEffect} from 'react';

import { Link, useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';

var parseOnce=false;
function Message(props) {

  let { obj } = useParams();
  obj = JSON.parse(obj);

  const [message, setMessage] = useState(false);

  if(!parseOnce) {
    parseOnce=true;
    // const query = new URLSearchParams(window.location.search);
    // const obj = JSON.parse(query.get('obj'));
    //console.log(obj);
    if(obj.redirect_url=='false') {
      obj.redirect_url=false;
    }
    if(obj.link_label=='false') {
      obj.link_label=false;
    }
    setMessage(obj);
  }


  return (
    <div className="page">
      <div className="text_block_white">
        <h1>{message.label}</h1><br/>
        <p>{message.description}</p>
        <p>

        <span className={message.link_label?'':'hidden'}>
        <Button variant={message.link_variant?message.link_variant:"dark"} size="lg" onClick={()=>{ window.open(message.redirect_url, '_self'); }}>
          {message.link_label}
        </Button>
        </span>

        <span className={message.link2_label?'':'hidden'}>
        <Button className="shift_back_button_on_form"  variant={message.link2_variant?message.link2_variant:"dark"} size="lg" onClick={()=>{ window.open(message.redirect2_url, '_self'); }}>
          {message.link2_label}
        </Button>
        </span>


        </p>
      </div>

    </div>);

}
  
export default Message;