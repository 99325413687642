import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import * as vars from './../vars.js';

import { ReactSession } from 'react-client-session';

var cats = [];
for(var i=0;i<vars.pages.length;i++) {
  var page = vars.pages[i];
  if(!cats[page.category]) {
    cats[page.category]=[];
  }
  cats[page.category].push(page);
}



//console.log(cats);


function Footer(props) {

  const [categories, setCategories] = useState([]);


  useEffect(() => {
    
    //console.log(cats);
    
    setCategories(cats);
    
    
  });

  function clicked(name) {

    if(name=='Logout') {
      props.logout();
    }

  }


  return (<>
  <div className="full_footer  no-print">
    
      {Object.keys(categories).map((category, index) => {
        if(category!='false') {
          return (<div key={index} className={"list left_list_"+index}><ul>
                  <li><span className="list_head"><a className="black_link" href={(cats[category][0].name==category?cats[category][0].href:undefined)}>{category}</a></span></li>

                  {vars.pages.map((page, index) => {
                     if(page.footer && page.category == category && page.name != category && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
                      return (<li key={index}><a className="black_link" onClick={()=>clicked(page.name)} href={page.href}>{page.name}</a></li>);
                    }
                  })}
                </ul></div>);
        }
        
      })}


    
    <div className="footer_address_box">
      
      
      <div className="footer_abox_left">
      <span className="less_margin"><h5>Last Mountain<br/>Wireless Inc.</h5></span>
      <a href="https://twitter.com/LastMtnWireless"><img className="social_img" src="/img/twitter.png" width="25" /></a>
      <a href="https://www.facebook.com/LastMountainWireless"><img className="social_img" src="/img/facebook.png" width="25" /></a>
      <a href=""><img className="social_img" src="/img/instagram.png" width="25" /></a>
      <p><a  className="black_link" href="tel://13069004321">1.306.900.4321</a><br/>
      Box #8, Govan, SK<br/>S0G 1Z0</p>
      </div>
      <div className="footer_abox_right"><img style={{cursor: 'pointer'}} onClick={()=>{window.open("/", '_self')}} src="/img/icon_on_yellow.png" width="60" /></div>
      
    </div>
    <div className="clear">
    </div>
    
  </div>

  <div className="mobile_footer">

    <img style={{cursor: 'pointer'}} src="/img/icon_on_yellow.png" onClick={()=>{window.open("/", '_self')}} width="100" /><br/><br/>
      
      <span className="less_margin"><h3>Last Mountain Wireless Inc.</h3></span>
      <a href="https://twitter.com/LastMtnWireless"><img className="social_img" src="/img/twitter.png" width="35" /></a>
      <a href="https://www.facebook.com/LastMountainWireless"><img className="social_img" src="/img/facebook.png" width="35" /></a>
      <a href=""><img className="social_img" src="/img/instagram.png" width="35" /></a>
      <p><a  className="black_link" href="tel://13069004321">1.306.900.4321</a><br/>
      Box #8, Govan, SK<br/>S0G 1Z0</p>


      <div className="no-print">
      {Object.keys(categories).map((category, index) => {
        if(category!='false') {
          return (<div key={index} className={"mobile_list mobile_footer_list_"+index}><ul>
                  <div className="list_head"><span className="white_link"><a  href={(cats[category][0].name==category?cats[category][0].href:undefined)}>{category}</a></span></div>
                  {vars.pages.map((page, index) => {
                    if(page.footer && page.category == category && page.name != category && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
                    
                      return (<li key={index}><a className="black_link"  onClick={()=>clicked(page.name)} href={page.href}>{page.name}</a></li>);
                    }
                  })}
                </ul></div>);
        }
        
      })}
      
      </div>

  

  </div>

  <div className="copyright no-print">Copyright 2023, Last Mountain Wireless Inc.</div>
  <br/><br/>

  </>);

}
  
export default Footer;