import React, {useState, useEffect} from 'react';

import { Link, useParams } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


import LmwForm from './LmwForm';
import SquareCC from './SquareCC';
import ReceiptTable from './ReceiptTable';

import Loading from './Loading';
import PleaseLogin from './PleaseLogin';

import * as vars from './../vars.js';
import * as funcs from './../funcs.js';

import { addDays, subDays, getDay } from 'date-fns';

var serialize = require('form-serialize');


var vpo=false;

var whenObj = {};

function OrderServiceSelect(props) {

  let { number } = useParams();

  ReactSession.setStoreType("localStorage");

  const [loading, setLoading] = useState(true);

  const [order, setOrder] = useState(false);

  const [render, setRender] = useState(0);


  const [internetPackageOpts, setInternetPackageOpts] = useState([]);

  

  const [startDate, setStartDate] = useState(new Date());
  const [startAltDate, setStartAltDate] = useState(new Date());

  const [validPreorder, setValidPreorder] = useState(false);


  

  async function detectPreorder() {
    const detectPreorder = await funcs.get('/orders/detectPreorder', {
      account_email: ReactSession.get("account_email"),
      account_token: ReactSession.get("account_token")
    }, (errors)=>{
      
    });

    if(detectPreorder.length>0) {
      setValidPreorder(true);
      vpo=true;
    } else {
      setValidPreorder(false);
      vpo=false;
    }

  }

 function packageSelected(package_id, useOrder) {



    if(!props.info) {
      console.log('no info');
      return;
    }

    var aPackage = props.info.packages[package_id];

    

    //console.log('Package selected: '+aPackage.name);

    // Modify the order graphically

    if(!order && !useOrder) {
      return;
    }

    if(!useOrder) {
      useOrder = order;
    }


    var oItems = [
      {value: aPackage.value, label: aPackage.name, shortDesc: aPackage.description, price: aPackage.oneTimePrice+aPackage.price, data: {
          service_location: { 
            lat: useOrder.receipts[0][0].data.service_location.lat,
            lng: useOrder.receipts[0][0].data.service_location.lng
          },
          package_id: package_id,
          service_address: useOrder.receipts[0][0].data.service_address
        }
      }
    ];
    var oItems2 = [];
    if(aPackage.requiresInstall) {
      oItems2.push({value: props.info.products[0].value, label: props.info.products[0].name, shortDesc: props.info.products[0].description, price: props.info.products[0].price});
    }

    if(vpo  && oItems[0].value!='preorder99' ) {
      oItems2.push({value: 'preorder_credit', label: 'Preorder Credit', shortDesc: 'Credit from your pre-order.', price: -99.00});
    }


    var newOrder = useOrder;
    newOrder.receipts = [];
    newOrder.receipts[0] = oItems;
    newOrder.receipts[1] = oItems2;

    if(oItems[0].value=='preorder99') {
      newOrder.type='preorder';
    } else {
      newOrder.type='service';
    }
    

    setOrder(newOrder);

    setRender(render+1);

    return newOrder;

  }


  useEffect(() => {

    whenObj.account = props.account;
    whenObj.info = props.info;

    funcs.whenAccountAndInfoLoaded(whenObj, async function() {

      
      await detectPreorder();

      const orderData = await funcs.get('/orders/'+number, {
        account_token: ReactSession.get("account_token"),
        account_email: ReactSession.get("account_email")
      }, (errors)=>{

          if(errors[0].code=='INVALID_TOKEN') {
            // If this is an account redirect error, set the account creation and login locations and messages...
            ReactSession.set("next_reason", 'To continue ordering service');
            ReactSession.set("next_url", errors[0].next_url);

          }

          for(var i=0;i<errors.length;i++) {
            if(errors[i].redirect_url) {
              window.open(errors[0].redirect_url, '_self');
            }
          }


      });
      setOrder(orderData);


      // console.log(orderData.desired_installation_date);
      if(orderData.desired_installation_date)
        setStartDate(new Date(orderData.desired_installation_date));
      if(orderData.desired_alternate_installation_date)
        setStartAltDate(new Date(orderData.desired_alternate_installation_date));

      
      console.log(orderData);

      if(orderData && orderData.receipts) {
        packageSelected(orderData.receipts[0][0].data.package_id, orderData);
      }


      var newOpts = [];
      for(var i=0;i<whenObj.info.packages.length;i++) {
        var newOpt = {};
        newOpt.label = whenObj.info.packages[i].name;
        newOpt.value = i;
        newOpts.push(newOpt);
      }
      setInternetPackageOpts(newOpts);

      setLoading(false);


    });

    
  });

  if(loading) { return (<><Loading /></>); }
  if(!props.account) { return (<><PleaseLogin /></>); }


  if(!order || internetPackageOpts.length < 1 || !number) {
    return (<>
      <div className="page">
      <div className="text_block_white block_text_align_center">
      Unable to retrieve order... <a href={window.location.href}>Refresh the page?</a>
      </div>
      </div>
      </>);
  }


  return (
    <div className="page">
      <div className="text_block_blue block_text_align_center">
      
      <div className="form_left">
          <h1>Order #{order.number}</h1>
          <p>Observe the invoice(s) below.</p>


          <div className={order.type=='service'?'':'hidden'}>
            <ReceiptTable label="Installation Invoice, Due Today" receipts={order.receipts[1]} render={render} /><br/>
            <ReceiptTable label="Monthly Invoice, Billing Begins in 2 Weeks" receipts={order.receipts[0]} render={render} />
            
          <br/></div>

          <div className={order.type=='preorder'?'':'hidden'}>
            <ReceiptTable label="Pre-order Invoice, Due Today" receipts={order.receipts[0]} render={render} />
          <br/></div>



        </div>
        <br/><br/>

        <LmwForm
         theme="light" 
         nextReason="To continue ordering service"
         id="service_order" 
         submitLabel="Continue" submitAction={"/orders/orderServiceSelect"} 
         buttonSize="lg"
         backLabel="Back" backAction="/availability"
        fields={[

          { type: 'select', name: 'package_id', label: 'Internet Package', mutedText: '', placeholder: '', defaultValue: ((order&&order.receipts)?order.receipts[0][0].data.package_id:''), selectOpts: internetPackageOpts, changed: function(name) {

            var element = document.getElementsByName(name)[0];

            for(var i=0;i<props.info.packages.length;i++) {
              var aPackage = props.info.packages[i];
              if(i==element.value) {
                packageSelected(i, order);
                break;
              }
            }

            
          }},

          { type: 'text', name: 'service_location', label: 'Service Location', mutedText: '', mutedLink: {href: "/availability", label: "Change Location"}, placeholder: '', defaultValue: (order?order.receipts[0][0].data.service_location.lat+', '+order.receipts[0][0].data.service_location.lng:''), readOnly: true},
          
          { type: 'spacer_short' },
          { type: 'hidden', name: 'account_email', defaultValue: ReactSession.get("account_email"), readOnly: true},
          { type: 'hidden', name: 'account_token', defaultValue: ReactSession.get("account_token"), readOnly: true},
          { type: 'hidden', name: 'order_number', defaultValue: (order?order.number:''), readOnly: true},
          { type: 'spacer_short' },

        ]} />
        <br/>        
        <br/>

        
        
      </div>


    </div>);

}
  
export default OrderServiceSelect;
