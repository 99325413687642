import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

function Privacy(props) {

  const [shiftImage1Style, setShiftImage1Style] = useState({backgroundPosition: 'right -10px'});

  window.addEventListener("scroll", (event) => {
    if(window !== undefined) {
      let scroll = window.scrollY;
      //console.log(scroll);
      setShiftImage1Style({backgroundPosition: 'right '+(-scroll*0.075-10)+'px'});
    }
  });


  return (
    <div className="page">
      <div className="text_block_white">
        <h3>Privacy Policy</h3><br/>
        <h5>Information Collected Through Facebook</h5>
        <p>We may collect your information through our Facebook campaigns. We do so in order to produce quotations for our services and to be able to correspond with you to arrange those services.</p>
        <p>We will not share this information with any third party unless that party assists us in providing these services.</p>
      </div>

    </div>);

}
  
export default Privacy;