import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

function Contact(props) {

  const [shiftImage1Style, setShiftImage1Style] = useState({backgroundPosition: 'right -10px'});

  window.addEventListener("scroll", (event) => {
    if(window !== undefined) {
      let scroll = window.scrollY;
      //console.log(scroll);
      setShiftImage1Style({backgroundPosition: 'right '+(-scroll*0.075-10)+'px'});
    }
  });


  return (
    <div className="page">
      <div className="text_block_white">
        <h3>Get in Touch</h3>
        <p>Please direct all inquiries to <span className="blue_link"><a href="mailto:info@lmw.email">info@lmw.email</a></span>.</p>
      </div>

    </div>);

}
  
export default Contact;