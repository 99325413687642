

const currentlyServing = 'Service now available in select communities';

const pages = [
  {name: "About", category: 'General', href:'/', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Starlink", category: 'General', href:'/starlink', header: false, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Contact", category: 'General', href:'/contact', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  //{name: "Login", category: 'Account', href:'/login', header: true, footer: true, logged_in_only: false, logged_out_only: true},
  //{name: "Logout", category: 'Account', href:'', header: true, footer: true, logged_in_only: true, logged_out_only: false},
 // {name: "Company", category: 'About', href:'/company', header: false, footer: true},
  // {name: "Plans", category: 'About', href:'/plans', header: true, footer: true},
  // {name: "Support", category: 'Service', href:'/support', header: true, footer: true},
  // {name: "Login", category: 'Service', href:'/login', header: true, footer: true},

  // {name: "Coverage", category: 'About', href:'/coverage', header: false, footer: true},
  // {name: "Technology", category: 'About', href:'/technology', header: false, footer: true},

  // {name: "Terms of Service", category: 'Legal', href:'/coverage', header: false, footer: true},
  // {name: "Acceptable Use Policy", category: 'Legal', href:'/technology', header: false, footer: true},
  {name: "Plans", category: 'General', href:'/availability', header: true, footer: true},
  {name: "Privacy", category: 'Legal', href:'/privacy', header: false, footer: true},
  {name: "Billing", category: 'Account', href:'https://billing.lastmountainwireless.com', header: true, footer: true},
  //{name: "Dashboard", category: 'Account', href:'/dashboard', header: false, footer: true, logged_in_only: true}

];







var apiUrl = 'https://api.lastmountainwireless.com';




const squareApplicationId = "sandbox-sq0idb-AKIb1qQkwRdAq1h8CuVlpA";

const squareLocationId = "LS5AGF9RFENNZ";

const loadingPollingIntervalSecondsFraction = 4; // 1/10th


export {
  loadingPollingIntervalSecondsFraction,
  squareLocationId,
  squareApplicationId,
  currentlyServing,
  pages,
  apiUrl
}