

import React, {useState, useEffect} from 'react';

import { Link, useParams } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


import LmwForm from './LmwForm';
import SquareCC from './SquareCC';
import ReceiptTable from './ReceiptTable';
import Loading from './Loading';
import PleaseLogin from './PleaseLogin';

import * as vars from './../vars.js';
import * as funcs from './../funcs.js';

import { addDays, subDays, getDay } from 'date-fns';

var serialize = require('form-serialize');


var whenObj = {};
function OrderServiceDate(props) {

  let { number } = useParams();

  ReactSession.setStoreType("localStorage");

  const [loading, setLoading] = useState(true);

  const [order, setOrder] = useState(false);

  const [render, setRender] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [startAltDate, setStartAltDate] = useState(new Date());
  
  
  useEffect(() => {

    whenObj.account = props.account;
    whenObj.info = props.info;

    funcs.whenAccountAndInfoLoaded(whenObj, async function() {
  

      const orderData = await funcs.get('/orders/'+number, {
        account_token: ReactSession.get("account_token"),
        account_email: ReactSession.get("account_email")
      }, (errors)=>{

          if(errors[0].code=='INVALID_TOKEN') {
            // If this is an account redirect error, set the account creation and login locations and messages...
            ReactSession.set("next_reason", 'To continue ordering service');
            ReactSession.set("next_url", errors[0].next_url);

          }

          for(var i=0;i<errors.length;i++) {
            if(errors[i].redirect_url) {
              window.open(errors[0].redirect_url, '_self');
            }
          }


      });
      setOrder(orderData);


      // console.log(orderData.desired_installation_date);
      if(orderData.desired_installation_date)
        setStartDate(new Date(orderData.desired_installation_date));
      if(orderData.desired_alternate_installation_date)
        setStartAltDate(new Date(orderData.desired_alternate_installation_date));

      
      console.log(orderData);

      setLoading(false);

    });

    
  });

  if(loading) { return (<><Loading /></>); }
  if(!props.account) { return (<><PleaseLogin /></>); }



  if(!order || !number) {
    return (<>
      <div className="page">
      <div className="text_block_white block_text_align_center">
      Unable to retrieve order... <a href={window.location.href}>Refresh the page?</a>
      </div>
      </div>
      </>);
  }


  return (
    <div className="page">
      <div className="text_block_blue block_text_align_center">
      
      <div className="form_left">
          <h1>Order #{order.number}</h1>  
          
          <p>Leave us a couple of possible installation dates, we'll call you to confirm one.</p>



        </div>
        <br/><br/>

        <LmwForm
         theme="light" 
         nextReason="To continue ordering service"
         id="service_order" 
         submitLabel="Continue to Payment" submitAction={"/orders/orderServiceDate"} 
         buttonSize="lg"
         backLabel="Back" backAction="/availability"
        fields={[
          
          { type: 'date', hideIfFieldValue: {field: 'package_id', value: 0}, name: 'desired_installation_date', label: 'Desired Installation Date', defaultValue: '', readOnly: false, dateArgs: {excludeDates: [addDays(new Date(), 1), addDays(new Date(), 2), addDays(new Date(), 3), addDays(new Date(), 4), , addDays(new Date(), 5)], filterDate: funcs.isWeekdayOrPassed, startDate: startDate, onChange: (date:Date) => setStartDate(date)  }},
          { type: 'date', hideIfFieldValue: {field: 'package_id', value: 0}, name: 'desired_alternate_installation_date', label: 'Desired Alternate Installation Date', defaultValue: '', readOnly: false, dateArgs: {excludeDates: [addDays(new Date(), 1), addDays(new Date(), 2), addDays(new Date(), 3), addDays(new Date(), 4), , addDays(new Date(), 5)], filterDate: funcs.isWeekdayOrPassed, startDate: startAltDate, onChange: (date:Date) => setStartAltDate(date) }},
          { type: 'hidden', name: 'account_email', defaultValue: ReactSession.get("account_email"), readOnly: true},
          { type: 'hidden', name: 'account_token', defaultValue: ReactSession.get("account_token"), readOnly: true},
          { type: 'hidden', name: 'order_number', defaultValue: (order?order.number:''), readOnly: true},
          { type: 'spacer_short' },

        ]} />
        <br/>        
        <br/>


        
        
      </div>


    </div>);

}
  
export default OrderServiceDate;
