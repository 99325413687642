import React, {useState, useEffect} from 'react';

import Header from './components/Header';
import Footer from './components/Footer';

import AdminDashboard from './components/AdminDashboard';
import AdminCustomer from './components/AdminCustomer';

import About from './components/About';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Activate from './components/Activate';
import NewPassword from './components/NewPassword';
import Logout from './components/Logout';
import CreateAccount from './components/CreateAccount';
import UpdateAccount from './components/UpdateAccount';
import AccountCreated from './components/AccountCreated';
import Privacy from './components/Privacy';

import Availability from './components/Availability';
import Service from './components/Service';

import ServiceUpgrade from './components/ServiceUpgrade';

import OrderServiceSelect from './components/OrderServiceSelect';
import OrderServiceDate from './components/OrderServiceDate';
import OrderServicePayment from './components/OrderServicePayment';

import Order from './components/Order';

import Starlink from './components/Starlink';
import StarlinkBooked from './components/StarlinkBooked';

import Contact from './components/Contact';
import Dashboard from './components/Dashboard';
import Message from './components/Message';

import * as vars from './vars.js';
import * as funcs from './funcs.js';


import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { ReactSession } from 'react-client-session';


var doOnce=false;

function App() {

  const [account, setAccount] = useState(false);

  const [info, setInfo] = useState(false);

  const [render, setRender] = useState(0);


  function logout() {
    ReactSession.set("account_email", false);
    ReactSession.set("account_token", false);

    ReactSession.set("next_reason", false);
    ReactSession.set("next_url", false);

    //window.history.replaceState(null, null, window.location.pathname);

    // remove token from href
    var newHref = window.location.href.replace('account_token=', 'removed_token=');

    

    if(window.location.href.indexOf('/dashboard') > -1) {
      newHref = newHref.replace('/dashboard', '/');
      //window.open(newHref, '_self');
    }

    window.history.replaceState(null, null, newHref);

  }


  useEffect(() => {

  //console.log('window.location.search: '+window.location.search);

    (async function() {

      if(!doOnce) {
        doOnce=true;

        const siteInfo = await funcs.get('/info', (errors)=>{});
        setInfo(siteInfo);


        var validAccount = await funcs.validateToken();
        setAccount(validAccount);


        setRender(render+1);

      }
    })();

    
    
  });

  return (
    <><Header account={account} logout={logout} info={info} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<><About /></>} />

          <Route path="/starlink" element={<><Starlink /></>} />
          <Route path="/starlinkBooked" element={<><StarlinkBooked /></>} />
          <Route path="/contact" element={<><Contact /></>} />
          <Route path="/dashboard" element={<><Dashboard account={account} info={info} /></>} />
          {/*<Route path="/login" element={<><Login /></>} />
          <Route path="/forgotPassword" element={<><ForgotPassword /></>} />
          <Route path="/newPassword" element={<><NewPassword /></>} />
          <Route path="/logout" element={<><Logout /></>} />*/}

          {/*<Route path="/activate" element={<><Activate account={account} /></>} />*/}

          <Route path="/privacy" element={<><Privacy /></>} />
          <Route path="/admin" element={<><AdminDashboard account={account} info={info} /></>} />
{/*

          <Route path="/adminCustomer/:ce" element={<><AdminCustomer account={account} info={info} /></>} />


        
          <Route path="/orders/:number" element={<><Order account={account} info={info} /></>} />


          <Route path="/services/:number" element={<><Service account={account} info={info} /></>} />

          <Route path="/serviceUpgrade/:number" element={<><ServiceUpgrade account={account} info={info} /></>} />

          <Route path="/orderServiceSelect/:number" element={<><OrderServiceSelect account={account} info={info} /></>} />
          <Route path="/orderServiceDate/:number" element={<><OrderServiceDate account={account} info={info} /></>} />
          <Route path="/orderServicePayment/:number" element={<><OrderServicePayment account={account} info={info} /></>} />


          <Route path="/createAccount" element={<><CreateAccount /></>} />
          <Route path="/updateAccount" element={<><UpdateAccount account={account} info={info} /></>} />
*/}
          <Route path="/message/:obj" element={<><Message account={account} /></>} />

          {/*<Route path="/accountCreated" element={<><AccountCreated /></>} />*/}
          <Route path="/availability" element={<><Availability info={info} render={render} /></>} />
          
          
        </Routes>
      </BrowserRouter>
      <Footer account={account} logout={logout} />
    </>
  );

  
}

export default App;
