import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import * as vars from './../vars.js';

import Button from 'react-bootstrap/Button';

import { ReactSession } from 'react-client-session';

import uuid from 'react-uuid';
import { sha256, sha224 } from 'js-sha256';

function InternetPackage(props) {

  ReactSession.setStoreType("localStorage");

  const [ourPackage, setOurPackage] = useState(false);


  useEffect(() => {
    
    if(props.info) {
      setOurPackage(props.info.packages[props.packageId]);
    }
    
  });


  function clickPackage(id) {
    

  }


  

  if(props.availability < ourPackage.availableRegion || props.availability > ourPackage.maxRegion) {
    return;
  }

  return (
    <>
    <div className="internetPackage">

      <h3>{ourPackage.name}</h3>
      <div className="packageDesc brand_color_text"><b><i>{ourPackage.marketing_description}</i></b></div>

      <div className={"packageSpeed "+(ourPackage.price>0?'':'hidden')}><b>{ourPackage.download}</b> Mbps down - <b>{ourPackage.upload}</b> Mbps up</div>
      
      <div className={"packagePrice "+(ourPackage.price>0?'':'hidden')}><span className="brand_color_text">${ourPackage.promo_price}<span className="packagePriceSmaller">/mo</span></span></div>
      <div className={""+(ourPackage.price!=ourPackage.promo_price?'':'hidden')}><span className="packagePriceEvenMoreSmallerYet">*Regular Price</span> <span className="brand_color_text">${ourPackage.price}<span className="packagePriceEvenMoreSmallerYet">/mo</span></span></div>
      <div className={"packagePrice "+(ourPackage.oneTimePrice>0?'':'hidden')}><span className="brand_color_text">${ourPackage.oneTimePrice}<span className="packagePriceSmaller"></span></span></div>
      <div className="finePrint">{ourPackage.finePrint}</div>
      <br/><Button onClick={() => { props.clickedOrder()}} variant="warning" size="lg" active>{ourPackage.orderButtonText}</Button>
      <br/><br/><br/><br/>
    <div className="clear"></div>
    </div>
    </>
        );

}
  
export default InternetPackage;