import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


import * as vars from './../vars.js';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, subDays, getDay } from 'date-fns';



var parseOnce=false;
function Starlink(props) {

  
  const [errMessage, setErrMessage] = useState("");
  const [errField, setErrField] = useState('');
  const [existingObj, setExistingObj] = useState(false);



  useEffect(() => {
    if(!parseOnce) {
      const queryParams = new URLSearchParams(window.location.search);
      const message = queryParams.get('message');
      const field = queryParams.get('field');
      const obj = queryParams.get('obj');
      setErrMessage(message);
      setErrField(field);
      setExistingObj(JSON.parse(obj));
      console.log(existingObj);
      if(existingObj)
        setStartDate(Date.parse(existingObj.installation_date));
      if(existingObj) {
        parseOnce=true;
      }
      
    }
    
    
  });

  

  const [shiftImage1Style, setShiftImage1Style] = useState({backgroundPosition: 'right -10px'});

  const [startDate, setStartDate] = useState(new Date());
  const isWeekdayOrPassed = (date) => {
    const day = getDay(date);

    const currentDate = new Date();
    const selectedDate = date;

    return day !== 0 && day !== 6 && selectedDate.getTime() >= currentDate.getTime();
  };

  window.addEventListener("scroll", (event) => {
    if(window !== undefined) {
      let scroll = window.scrollY;
      //console.log(scroll);
      setShiftImage1Style({backgroundPosition: 'right '+(-scroll*0.075-10)+'px'});
    }
  });

  

  return (
    <div className="page">
      <div style={{display: !errMessage? 'block':'none'}}>
      <div className="image2" style={shiftImage1Style}>
        <h1>Starlink Installations</h1>
        <div className="left_text_box">
        <h4><span className="brand_color_text"><i>Starlink is in Saskatchewan.</i></span></h4><br/><br/>
        <p>If you haven't already visit <a className="white_link" href="https://starlink.com"><b>starlink.com</b></a>, enter your location, and order the service.</p>
        <p>You'll receive a Starlink dish in the mail, and in most cases you can install it yourself.</p>
        <p>However, if you'd like the help of our technicians and equipment; <br/>Last Mountain Wireless is currently offering <i><span className="brand_color_text"><b>professional installations for $250</b></span> (travel and some hardware extra).</i></p>
        </div><br/><br/>
        <Button href="tel://1-825-435-1010" variant="primary" size="lg" active>Text or Call 1.825.435.1010</Button>
      </div>
      <div className="text_block_white" id="learn_more">
        <h3>A Clear View of the Sky</h3>
        <p>We'll help you determine the best position for your Starlink dish on your property (typically on the roof of your home). You will have fewer disconnects and higher speeds with an unobstructed view of the sky.</p>
        <h3>A Variety of Mounting Hardware</h3>
        <p>We utilize a variety of mounting hardware for your dish including tripods, j-mounts, telescoping poles, non-penetrating roof mounts and antenna towers.</p>
        <h3>Aerial Work Platforms</h3>
        <p>If your installation requires it, we can send a bucket truck to help reach antennas, high roofs, slippery tin roofs, roofs with a steep pitch or any combination thereof.</p>
        <h3>Up On the Roof</h3>
        <p>If possible we will avoid penetrating your roof entirely, but if we're not able to, we will minimize the number of holes we create and we'll properly caulk, seal and/or tar them.</p>
        <h3>Fit, Finish and Seal</h3>
        <p>We usually have to drill a single hole through an exterior wall in order to bring the Starlink wire into your home. If possible we will use a marine grade exterior cover in order to seal that hole from the elements (included at no charge), and if we end up in a finished room on the inside we will offer to use an interior faceplate as well.</p>
        <br/>
        <h5><i>*Please Note</i></h5>
        <p>We do not supply or finance the Starlink hardware. You must purchase the Starlink hardware and subscribe to the service through <a href="https://starlink.com"><b>starlink.com</b></a>.</p>
        
      </div>
      </div>
      
      
      

    </div>);

}
  
export default Starlink;