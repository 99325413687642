import React, {useState, useEffect} from 'react';

import { Link, useParams } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import ReceiptTable from './ReceiptTable';
import LmwForm from './LmwForm';
import SquareCC from './SquareCC';
import Loading from './Loading';
import PleaseLogin from './PleaseLogin';

import * as vars from './../vars.js';
import * as funcs from './../funcs.js';

import uuid from 'react-uuid';
import { sha256, sha224 } from 'js-sha256';

var serialize = require('form-serialize');

function consoleLog(obj) {
  console.log(JSON.stringify(obj, null, 2));
}


//var vpo=false;

var whenObj = {};
function OrderServicePayment(props) {

  let { number } = useParams();

  ReactSession.setStoreType("localStorage");

  const [order, setOrder] = useState(false);

  const [render, setRender] = useState(0);

  const [loading, setLoading] = useState(true);
  
  useEffect(() => {

    whenObj.account = props.account;
    whenObj.info = props.info;

    funcs.whenAccountAndInfoLoaded(whenObj, async function() {
  

      const orderData = await funcs.get('/orders/'+number, {
          account_token: ReactSession.get("account_token"),
          account_email: ReactSession.get("account_email")
        }, (errors)=>{

          if(errors[0].code=='INVALID_TOKEN') {
            // If this is an account redirect error, set the account creation and login locations and messages...
            ReactSession.set("next_reason", 'To continue paying for service');
            ReactSession.set("next_url", errors[0].next_url);

          }

          for(var i=0;i<errors.length;i++) {
            if(errors[i].redirect_url) {
              window.open(errors[0].redirect_url, '_self');
            }
          }


        });
        setOrder(orderData);

      setLoading(false);

    });

    
  });

  if(loading) { return (<><Loading /></>); }
  if(!props.account) { return (<><PleaseLogin /></>); }


  if(!order || !number) {
    return (<>
      <div className="page">
      <div className="text_block_white block_text_align_center">
      Unable to retrieve order...
      </div>
      </div>
      </>);
  }


  return (
    <div className="page">
      <div className="text_block_white block_text_align_center">

        <div className="form_left">
          <h1>Order #{order.number}</h1>
          <p>Observe the invoice(s) below.</p>

           <div className={order.type=='service'?'':'hidden'}>
            <ReceiptTable label="Installation Invoice, Due Today" receipts={order.receipts[1]} render={render} /><br/>
            <ReceiptTable label="Monthly Invoice, Billing Begins in 2 Weeks" receipts={order.receipts[0]} render={render} />
            
          <br/></div>

          <div className={order.type=='preorder'?'':'hidden'}>
            <ReceiptTable label="Pre-order Invoice, Due Today" receipts={order.receipts[0]} render={render} />
          <br/></div>

        </div>

        <LmwForm
          id="service_order_payment" theme="light" 
          nextReason="To continue paying for service"
          submitLabel="" submitAction="/orders/orderServicePayment" submitted={function() {
            document.getElementById('card-button').disabled = false;
          }}
          backLabel="" backAction="" 
          buttonSize="lg"
          fields={[
            { type: 'hidden', name: 'order_number', label: 'Order #',  defaultValue: (order?order.number:''), readOnly: true},
            { type: 'hidden', name: 'account_email', defaultValue: ReactSession.get("account_email"), readOnly: true},
            { type: 'hidden', name: 'account_token', defaultValue: ReactSession.get("account_token"), readOnly: true},

            { type: 'spacer', label: 'Billing Address'},
            { type: 'text', name: 'billing_cardholder_name', label: 'Cardholder Name', placeholder: 'John Smith', defaultValue: (props.account?(props.account.billing_address.cardholder_name):''), readOnly: false},
            { type: 'text', name: 'billing_address_line_1', label: 'Address Line 1', placeholder: '1234 Mountain Rd', defaultValue: (props.account?props.account.billing_address.address_line_1:''), readOnly: false},
            { type: 'text', name: 'billing_address_line_2', label: 'Address Line 2', placeholder: '', defaultValue: (props.account?props.account.billing_address.address_line_2:''), readOnly: false},
            { type: 'text', name: 'billing_locality', label: 'City/Town', placeholder: 'Strasbourg', defaultValue: (props.account?props.account.billing_address.locality:''), readOnly: false},
            { type: 'select', name: 'billing_province', label: 'Province', placeholder: 'SK', defaultValue: (props.account?funcs.labelForProvinceValue(props.account.billing_address.province):''), readOnly: false, selectOpts: [{value: 'SK', label: 'Saskatchewan'}]},
            { type: 'text', name: 'billing_postal_code', label: 'Postal Code', placeholder: 'S4T 0R7', defaultValue: (props.account?props.account.billing_address.postal_code:''), readOnly: false},
            { type: 'select', name: 'billing_country', label: 'Country', placeholder: 'CA', defaultValue: (props.account?funcs.labelForCountryValue(props.account.billing_address.country):''), readOnly: false, selectOpts: [{value: 'CA', label: 'Canada'}]},

            
            { type: 'spacer_short', label: 'Card Number'},

            { type: 'hidden', name: 'cc_token',  value: '', readOnly: true},

          ]} />

        <br/>        
        <br/>
        <div className="form_left">

      
         <SquareCC backLabel="Back" backAction={'/orderServiceSelect/'+order.number} account={props.account} chargeAmount={funcs.getTotalForServiceOrder(order, props.info.packages)} cardEntered={function (obj) {
          document.querySelector('[name="cc_token"]').value = obj.sourceId;
          //postForm();
          document.getElementById('card-button').disabled = true;
          document.getElementById('lmwform_service_order_payment_submit').click();
         }} />
         <br/>

         

        </div>
        
        
      </div>

    </div>);

}
  
export default OrderServicePayment;
